export const API_ROOT = '/api'
export const APP_API_ROOT = process.env.APP_API_ROOT
export const APP_LOGIN_API_ROOT = process.env.APP_LOGIN_API_ROOT
export const S3_FILE_UPLOAD_ROOT = process.env.S3_FILE_UPLOAD_ROOT


//admin
export const ADMIN_SESSION_API = '/admin/session/validate';
export const ADMIN_LOGIN_API = '/admin/session/login';
export const ADMIN_UPLOAD_API = '/upload'
export const ADMIN_UPLOAD_S3_API = '/file/upload'

//all pages/post/help api
export const WEB_SITE_POST_LIST_API = '/post/website-list'
export const WEB_SITE_HOME_RESUME_LIST_API = '/post/home-resume-list'
export const WEB_SITE_POST_SEARCH_LIST_API = '/post/website-search-list'
export const WEB_SITE_POST_SUGGESTION_API = '/post/suggestion-post-list'
export const POST_LIST_API = '/post/list'
export const POST_PATH_LIST_API = '/post/post-url-list'
export const POST_LIST_SEARCH_API = '/post/search-list'
export const POST_LIST_RECENT_API_BLOG = '/post/recent-blog-list'
export const POST_BLOG_LIST_SEARCH_API = '/post/blog-search-list'
export const POST_VIEW_API = '/post/view'
export const POST_SAVE_API = '/post/save'
export const POST_PREVIEW_SAVE_API = '/post/save-preview'
export const POST_LIST_POP_API = '/post/list-pop'

//all category/topic api
export const TITLE_LIST_SEARCH_API = '/title/search-list'
export const TITLE_LIST_API = '/title/list'
export const TITLE_URL_LIST_API = '/title/title-url-list'
export const TITLE_VIEW_API = '/title/view'
export const TITLE_SAVE_API = '/title/save'

export const SETTING_VIEW_API = '/admin/setting/view'
export const SETTING_SAVE_API = '/admin/setting/save'
//all author api
export const AUTHOR_LIST_SEARCH_API = '/admin/author/search-list'
export const AUTHOR_LIST_API = '/admin/author/list'
export const AUTHOR_VIEW_API = '/admin/author/view'
export const AUTHOR_SAVE_API = '/admin/author/save'
export const VIEW_SAVE_API = '/admin/view/add'


//external api
export const EXTERNAL_CONVERSION_API = '/external/conversion';
export const EXTERNAL_CATEGORY_LIST_API = '/external/category-list';
export const EXTERNAL_SKILLS_LIST_API = '/external/skill-list';
export const EXTERNAL_JOB_LIST_API = '/external/job-list'
export const EXTERNAL_LOGIN_API = '/api/v1/auth/login';
export const EXTERNAL_JOBSEEKER_SEARCH_API = '/external/job-seekers-search'
export const EXTERNAL_JOB_PROFILE_API = '/external/job-profile'
export const EXTERNAL_ADD_CATEGORY_API = '/external/category-add'
export const EXTERNAL_EDIT_CATEGORY_API = '/external/category-edit'
export const EXTERNAL_ADD_SKILL_API = '/external/skill-add'
export const EXTERNAL_EDIT_SKILL_API = '/external/skill-edit'
export const EXTERNAL_INDIVIDUAL_JOB_API = '/external/individual-job'
export const EXTERNAL_RECENT_JOBSEEKER_API = '/external/recent-list'
export const EXTERNAL_CREATE_ENTERPRISE_CLIENT_API = '/external/add-enterprise'
export const EXTERNAL_CREATE_SALARY_CALCULATOR_CLIENT_API = '/external/salary-calculator'

//preview api
export const PREVIEW_JOB_API = '/api/preview/job'
export const PREVIEW_RESUMES_API = '/api/preview/resumes'
export const PREVIEW_HELP_ARTICLE_API = '/api/preview/help_article'
export const PREVIEW_BLOG_API = '/api/preview/blog'

//website_menu
export const WEBSITE_HELP_MENU_API = '/title/help-menu'


export const WEBSITE_HELP_AUTO_API = '/admin/autocomplete/data'
export const COST_ESTIMATE_ADD = '/admin/cost-estimate/add'

// dynamic-slider api
export const DYNAMIC_SLIDER_LIST_API = "/dynamic-slider/list";
export const DYNAMIC_SLIDER_ADD_API = "/dynamic-slider/add";
export const DYNAMIC_SLIDER_UPDATE_API = "/dynamic-slider/update";
export const DYNAMIC_SLIDER_DELETE_API = "/dynamic-slider/delete";
export const DYNAMIC_SLIDER_VIEW_API = "/dynamic-slider/view";


// dynamic-slider api
export const ENTERPRISE_DEALS_LIST_API = "/enterprise-deals/list";
export const ENTERPRISE_DEALS_ADD_API = "/enterprise-deals/add";
export const ENTERPRISE_DEALS_UPDATE_API = "/enterprise-deals/update";
export const GET_STATUS_PERCENTAGE_API = `/enterprise-deals/percentage`
export const GET_TOTAL_CLIENTS = `/enterprise-deals/totalClients`


// auth-localhost
export const AUTH_LOCALHOST = "https://authapi.virtualstaff.ph/api/v1";

//market-dashboard api
export const MARKETING_DASHBOARD = "/marketing-dashboard"
export const MARKETING_DASHBOARD_LIST_USERS = `${MARKETING_DASHBOARD}/listUsers`
export const MARKETING_DASHBOARD_EXPORT_USERS = `${MARKETING_DASHBOARD}/exportUsers`
export const MARKETING_DASHBOARD_GET_HIRED_COUNT = `${MARKETING_DASHBOARD}/getHiredCount`
export const MARKETING_DASHBOARD_GET_SUBSCRIPTION_COUNT = `${MARKETING_DASHBOARD}/getSubscriptionCount`
export const MARKETING_DASHBOARD_FREE_TRIAL_LIST = `${MARKETING_DASHBOARD}/free-trial-list`
export const CALENDLY_API_APPOINMENT_COUNT = `${MARKETING_DASHBOARD}/calendly`
export const GET_CALENDLY_SOURCE_UTM_PARAMS = `${MARKETING_DASHBOARD}/enterprise-source`
export const GET_TALENT_CLIENT_PERCENTAGE=`${MARKETING_DASHBOARD}/TalentClientCount`
export const GET_SOURCE_DATA = `${MARKETING_DASHBOARD}/sourceDashboard`
export const GET_USER_TRACKED_DATA = '/userTracker/getUserTracking'
export const GET_ERROR_LOGS = '/userTracker/getErrorLogs'